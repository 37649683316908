import React from "react";
import { Modal, Row, Col, Button, FullModal } from "atoms";
import { FullModalInner, FullModalStickyHeader } from "atoms/Modal";
import { Flex } from "antd";
import { FaAngleLeft } from "icons";

export const SaveAndExitModal = ({ open, onExitWithoutSave, onSaveAndExit, loading }) => {

  return (
     <Modal
       title={<span style={{ fontWeight: '500' }}>You Have Unsaved Changes</span>} 
       open={open}
       centered
       zIndex={1200}
       bodyStyle={{ minHeight: '120px'}}
       width={'440px'}
     >
       <Row gutter={[20, 10]}>
         <Col flex={`1 1 37%`}>
           <Button type={'default'} block onClick={onExitWithoutSave}>EXIT WITHOUT SAVING</Button>
         </Col>
         <Col flex={`1 1 23%`}>
           <Button block onClick={onSaveAndExit} loading={loading}>SAVE AND EXIT</Button>
         </Col>
       </Row>
     </Modal>
   );
};

export const FormFullModal = ({
  open,
  onCancel,
  onBack,
  loading,
  onSaveChanges,
  children
}) => {
  return (
    <FullModal
      open={open}
      onCancel={onCancel}
      destroyOnClose
    >
      <FullModalStickyHeader>
        <FullModalInner>
          <Flex justify="space-between">
            <Button type="default" icon={<FaAngleLeft />} onClick={onBack}>Back</Button>
            <Button type="primary" onClick={onSaveChanges} loading={loading}>Save Changes</Button>
          </Flex>
        </FullModalInner>
      </FullModalStickyHeader>
      <FullModalInner>
        {children}
      </FullModalInner>
    </FullModal>
  )
}